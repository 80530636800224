<h1>Orders</h1>

<div [ngClass]="{ 'list-container': true, visible: true }">
    <lib-visible-column-dropdown
        [columns]="columns"
        [section]="'orders'"
        (onColumnSelected)="setColumnsVisibility($event)"
        [class]="'float-left'"
    >
    </lib-visible-column-dropdown>
    <lib-set-user-setting-btn
        [btnClass]="'btn btn-sm btn-secondary float-left ml-2'"
        [section]="'orders'"
        [key]="'visibleColumns'"
        [value]="selectedColumnKeys"
    >
        {{ 'common.set-as-default' | translate }}
    </lib-set-user-setting-btn>
    <div class="table-responsive">
        <table class="table table-hover">
            <thead
                *ngIf="areColumnsReady"
                (onFilter)="listService.onColumnFilter($event)"
                (onSort)="listService.onColumnSort($event)"
                [columns]="columns"
                class="thead-light"
                interactive-table-header
            ></thead>
            <tbody *ngIf="(ordersService.orders | async).length > 0; else noresults">
                <tr *ngFor="let order of ordersService.orders | async; index as i">
                    <th scope="row">{{ listService.listIndexOffset + i + 1 }}</th>
                    <td *ngIf="isVisibleColumn(0)">
                        <span
                            [ngClass]="
                                order.isSuperOrder
                                    ? 'badge badge-danger'
                                    : 'badge badge-warning'
                            "
                            (click)="openOrderItemsModal(order)"
                            >{{ order.orderCode }}</span
                        >
                    </td>
                    <td *ngIf="isVisibleColumn(1)">
                        <span
                            [ngClass]="'badge badge-primary'"
                            (click)="openOrderItemsModal(order)"
                            >{{ order.superOrderCode }}</span
                        >
                    </td>
                    <td *ngIf="isVisibleColumn(2)">{{ order.providerName }}</td>
                    <td *ngIf="isVisibleColumn(3)">{{ order.ordererName }}</td>
                    <td *ngIf="isVisibleColumn(4)" class="text-nowrap">
                        {{ order.created | amDateFormat : 'YYYY.MM.DD. HH:mm' }}
                    </td>
                    <td *ngIf="isVisibleColumn(5)" class="text-nowrap">
                        <span>
                            {{
                                order.deliveryDate
                                    | amAdd : order.timeSlotVisualShiftMinutes : 'minutes'
                                    | amDateFormat : 'YYYY.MM.DD. HH:mm'
                            }}
                        </span>
                        <span *ngIf="order.deliveryTimeSlotEnd != null">
                            -
                            {{
                                order.deliveryDate
                                    | amAdd
                                        : order.deliveryTimeSlotEnd +
                                              order.timeSlotVisualShiftMinutes
                                        : 'minutes'
                                    | amDateFormat : 'HH:mm'
                            }}
                        </span>
                    </td>
                    <td *ngIf="isVisibleColumn(6)" class="text-nowrap">
                        {{ order.deliveryTypeName }}
                    </td>
                    <td *ngIf="isVisibleColumn(7)" class="text-nowrap">
                        {{ order.deliveryAddress }}
                    </td>
                    <td *ngIf="isVisibleColumn(8)">
                        <app-state-badge [state]="order.orderState"></app-state-badge>
                    </td>
                    <td *ngIf="isVisibleColumn(9)">
                        <app-payment-state-badge
                            [state]="order.paymentState"
                        ></app-payment-state-badge>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ng-template #noresults>
        <tbody>
            <tr>
                <td colspan="6">No results found with the given filter parameters.</td>
            </tr>
        </tbody>
    </ng-template>
    <div class="d-flex justify-content-center">
        <lib-page-size-dropdown
            class="page-size-selector"
            (onPageSizeChanged)="listService.setPageSize($event)"
        ></lib-page-size-dropdown>
        <ngb-pagination
            [(page)]="listService.currentPaginatorPage"
            [pageSize]="(listService.listProperties | async).pageSize"
            [collectionSize]="(listService.listProperties | async).rowCount"
            [boundaryLinks]="true"
            [rotate]="true"
            [maxSize]="20"
            (pageChange)="listService.onPageChange()"
        >
        </ngb-pagination>
    </div>
</div>
<app-order-details-modal></app-order-details-modal>
