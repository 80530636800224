import { ProviderDepositProduct } from './provider-deposit-product';

export class OfflineProduct {
    public providerOfflineProductID: number;
    public sku: string;
    public unit: string;
    public quantity: number;
    public productName: string;
    public isValid: boolean;
    public gtinList: string[];
    public vat: number;
    public markupPrice: number;
    public price: number;
    public productPhotoID: string;
    public providerProductImageURL: string;
    public categoryNameToDisplayTranslationCode: string;
    public isWerwProduct: boolean;
    public isPriceForced: boolean;
    public isBulk: boolean;
    public productProviderID: number;
    public providerDepositProductList: ProviderDepositProduct[];
}
