import { Component, Inject, Input, OnInit } from '@angular/core';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { CollectingStatesService } from '../../collecting-states.service';
import { CollectingActionsService } from '../../collecting-actions.service';
import { NativeTakePhotoService } from 'dm-src/services/native-take-photo/native-take-photo.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { take } from 'rxjs/operators';
import { ModalService } from 'shared';

@Component({
    selector: 'app-photo-button',
    templateUrl: './photo-button.component.html',
    styleUrls: ['./photo-button.component.scss'],
})
export class PhotoButtonComponent implements OnInit {
    public billImgPlaceholderUrl: string = 'https://delivermanagermedia.blob.core.windows.net/billphotos/invoice.jpg';
    public photoLoaded: boolean;
    @Input() public disabled = false;
    isWrapping = false;
    private isFirstLoad = true;

    public billImgSrc: string;
    public billImgID: string;

    public isBillImgRequired: boolean = true;
    public isBillImgIDRequired: boolean = true;

    isNewWrapper = !!window.ReactNativeWebView;
    constructor(
        private _states: CollectingStatesService,
        private _actions: CollectingActionsService,
        public modalService: ModalService,
        private _nativeTakePhotoService: NativeTakePhotoService,
        private _isWrappingService: IsWrappingService,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {
        this.photoLoaded = false;
    }

    ngOnInit() {
        this.isWrapping = this._isWrappingService.isWrapping();

        if (window.ReactNativeWebView) {
            this._iosAndAndroidNatvieFunctionsService
                .getCurrentPostMessage()
                .pipe(take(2))
                .subscribe((response) => {
                    if (this.isFirstLoad) {
                        this.isFirstLoad = false;
                        return;
                    }
                    if (response.functionName === 'CAMERA_START') {
                        this.billImgSrc = 'data:image/jpeg;base64,' + response.result;
                        this.photoLoaded = true;
                        this._states.isPhotoReady = true;
                        this._actions.setBillPhotoImageContent(
                            'data:image/jpeg;base64,' + response.result,
                            'jpeg' // Android/ios app use only
                        );
                    }
                });
        }
    }

    public showBillPhotoPopup() {
        this.resetAll();
        this.modalService.setModalVisibility(
            true,
            'bill-photo-popup'
        );
    }

    public saveBillPhoto() {
        this._actions.setBillPhotoID(
            this.billImgID
        );
        this.modalService.setModalVisibility(
            false,
            'bill-photo-popup'
        );
    }

    public get isBillImgInvalid() {
        return (
            this.isBillImgRequired &&
            (this.billImgSrc == '' || !this.billImgSrc)
        );
    }

    public get isBillImgIDInvalid() {
        return (
            this.isBillImgIDRequired &&
            (this.billImgID == '' || !this.billImgID)
        );
    }

    public resetAll() {
        this.billImgID = '';
        this.deletePhoto();
        this._actions.unsetBillPhotoImageContent();
        this._actions.unsetBillPhotoID();
    }

    public deletePhoto(): void {
        this.billImgSrc = '';
        this.photoLoaded = false;
        this._states.isPhotoReady = false;
    }

    public onPhotoInputChanged(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            const reader = new FileReader();

            reader.onload = (e) => {
                this.billImgSrc = e.target.result as string;
                this.photoLoaded = true;
                this._states.isPhotoReady = true;
            };

            reader.onloadend = (e) => {
                const imageExtension = fileInput.target.files[0].name
                    .split('.')
                    .pop()
                    .toLowerCase();
                this._actions.setBillPhotoImageContent(
                    reader.result.toString(),
                    imageExtension
                );
            };

            reader.readAsDataURL(fileInput.target.files[0]);
        }
    }

    handleTakeAPhoto() {
        this._iosAndAndroidNatvieFunctionsService.takeAPicture();
        if (this.isWrapping) {
            this._nativeTakePhotoService.getBase64ContentResult().subscribe((result) => {
                if (result) {
                    this.billImgSrc = result;
                    this.photoLoaded = true;
                    this._states.isPhotoReady = true;
                    this._actions.setBillPhotoImageContent(
                        result,
                        'png' // Android/ios app use only
                    );
                }
            });
        }
    }
}
