import { UserRole } from 'shared';

export let NavMenuItems: any[] = [
    {
        label: 'common.login',
        path: 'login',
        isAuthVisible: false,
    },
    {
        label: 'navigation.dashboard',
        path: '',
        isAuthVisible: true,
    },
    {
        label: 'navigation.get-delivering-task',
        path: 'task-generator',
        visibleFor: [UserRole.Deliverer],
    },
    {
        label: 'navigation.my-tasks',
        path: 'my-tasks',
        visibleFor: [UserRole.Picker, UserRole.Deliverer],
    },
    {
        label: 'navigation.my-worktimes',
        path: 'my-worktimes',
        visibleFor: [UserRole.Picker, UserRole.Deliverer],
    },
    {
        label: 'common.users',
        visibleFor: [UserRole.Administrator],
        children: [
            {
                label: 'navigation.accounts',
                path: 'accounts',
                visibleFor: [UserRole.Administrator],
            },
            {
                label: 'navigation.supplier-resources',
                path: 'supplier-resources',
                visibleFor: [UserRole.Administrator],
            },
            {
                label: 'navigation.supplier-regions',
                path: 'supplier-regions',
                visibleFor: [UserRole.Administrator, UserRole.Supporter],
            },
        ],
    },
    {
        label: 'navigation.my-account',
        path: 'my-account',
        visibleFor: [UserRole.User],
    },
    {
        label: 'navigation.orders',
        visibleFor: [UserRole.Administrator],
        children: [
            {
                label: 'navigation.orders',
                path: 'orders',
                visibleFor: [UserRole.Administrator],
                hasInitialProgressLine: true,
            },
            {
                label: 'navigation.tasks',
                path: 'tasks',
                visibleFor: [UserRole.Administrator],
                hasInitialProgressLine: true,
            },
            {
                label: 'navigation.tour-planner',
                path: 'tour-planner',
                visibleFor: [UserRole.Administrator],
                hasInitialProgressLine: true,
            },
        ],
    },
    {
        label: 'navigation.orders',
        visibleFor: [UserRole.ProviderAdmin],
        path: 'provider/orders',
        hasInitialProgressLine: true,
    },
    {
        label: 'navigation.daily-review',
        visibleFor: [UserRole.Picker, UserRole.Deliverer],
        path: 'daily-review',
        hasInitialProgressLine: true,
    },
    {
        label: 'navigation.knowledge-base',
        visibleFor: [UserRole.User],
        path: 'knowledge-base',
        hasInitialProgressLine: true,
    },
    {
        label: 'navigation.registration',
        path: 'registration',
        isAuthVisible: false,
    },
    {
        label: 'common.logout',
        path: 'logout',
        isAuthVisible: true,
    },
];
