import { Component, Input, OnInit } from '@angular/core';
import { DepositService } from '../../deposit.service';
import { CollectingStatesService } from '../../../collecting/collecting-states.service';
import { NativeTakePhotoService } from 'dm-src/services/native-take-photo/native-take-photo.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { NativeBarcodeReaderService } from 'dm-src/services/native-barcode-reader/native-barcode-reader.service';

import { take } from 'rxjs/operators';
import { ModalService } from 'shared';

@Component({
    selector: 'app-deposit-voucher-photo-uploader-modal',
    templateUrl: './deposit-voucher-photo-uploader-modal.component.html',
    styleUrls: ['./deposit-voucher-photo-uploader-modal.component.scss'],
})
export class DepositVoucherPhotoUploaderModalComponent implements OnInit {
    @Input() public disabled = false;

    public voucherPhotoPlaceholderUrl: string =
        'https://delivermanagermedia.blob.core.windows.net/billphotos/voucher.jpg';

    public voucherPhotoLoaded: boolean;

    isWrapping = false;

    isVoucherPhotoReady: boolean = false;

    public orderCode: string;

    public voucherPhotoSrc: string;

    public isVoucherPhotoRequired: boolean = true;

    public isOrderCodeRequired: boolean = true;

    isNewWrapper = !!window.ReactNativeWebView;

    constructor(
        public modalService: ModalService,
        private _states: CollectingStatesService,
        private _depositService: DepositService,
        private _nativeTakePhotoService: NativeTakePhotoService,
        private _nativeBarcodeReaderService: NativeBarcodeReaderService,
        private _isWrappingService: IsWrappingService,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {
        this.voucherPhotoLoaded = false;
    }

    ngOnInit() {
        this.isWrapping = this._isWrappingService.isWrapping();

        if (window.ReactNativeWebView) {
            this._iosAndAndroidNatvieFunctionsService
                .getCurrentPostMessage()
                .subscribe((response) => {
                    if (response.functionName === 'CAMERA_START') {
                        this.voucherPhotoSrc =
                            'data:image/jpeg;base64,' + response.result;
                        this.voucherPhotoLoaded = true;
                        this.isVoucherPhotoReady = true;
                        this._depositService.setVoucherPhotoContent(
                            'data:image/jpeg;base64,' + response.result,
                            this.orderCode,
                            'jpeg' // Android/ios app use only
                        );
                    }
                });
        }
    }

    public saveVoucherPhoto() {
        this.deletePhoto();

        this.modalService.setModalVisibility(
            false,
            'deposit-voucher-photo-uploader-modal'
        );

        if (window.ReactNativeWebView) {
            this._iosAndAndroidNatvieFunctionsService.openBarcodeReader();
        } else if (this._isWrappingService.isWrapping()) {
            this._iosAndAndroidNatvieFunctionsService.openBarcodeReader();
            this._nativeBarcodeReaderService.initBarcodeReading();
        } else {
            this._states.setBarcodeReaderModalVisible(true);
        }
    }

    public get isVoucherPhotoInvalid() {
        return (
            this.isVoucherPhotoRequired &&
            (this.voucherPhotoSrc == '' || !this.voucherPhotoSrc)
        );
    }

    public get isOrderCodeInvalid() {
        return (
            this.isOrderCodeRequired &&
            (this.orderCode == '' || !this.orderCode || this.orderCode?.length != 8)
        );
    }

    public resetAll() {
        this.deletePhoto();
        this._depositService.resetAll();
    }

    public deletePhoto(): void {
        this.voucherPhotoSrc = '';
        this.voucherPhotoLoaded = false;
        this.isVoucherPhotoReady = false;
    }

    public onVoucherPhotoInputChanged(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            const reader = new FileReader();

            reader.onload = (e) => {
                this.voucherPhotoSrc = e.target.result as string;
                this.voucherPhotoLoaded = true;
                this.isVoucherPhotoReady = true;
            };

            reader.onloadend = (e) => {
                const imageExtension = fileInput.target.files[0].name
                    .split('.')
                    .pop()
                    .toLowerCase();
                this._depositService.setVoucherPhotoContent(
                    reader.result.toString(),
                    this.orderCode,
                    imageExtension
                );
            };

            reader.readAsDataURL(fileInput.target.files[0]);
        }
    }

    handleTakeAVoucherPhoto() {
        this._iosAndAndroidNatvieFunctionsService.takeAPicture();
        if (this.isWrapping) {
            this._nativeTakePhotoService.getBase64ContentResult().subscribe((result) => {
                if (result) {
                    this.voucherPhotoSrc = result;
                    this.voucherPhotoLoaded = true;
                    this.isVoucherPhotoReady = true;
                    this._depositService.setVoucherPhotoContent(
                        result,
                        this.orderCode,
                        'png' // Android/ios app use only
                    );
                }
            });
        }
    }
}
