import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReturnDepositComponent } from './return-deposit.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';
import { SharedModule } from 'shared';

@NgModule({
    declarations: [ReturnDepositComponent],
    imports: [
        CommonModule,
        TranslateModule,
        NgxSelectModule,
        NgbNavModule,
        AppSharedModule,
        SharedModule,
    ],
})
export class ReturnDepositModule {}
