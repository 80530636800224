<lib-modal
    [title]="'deposit.voucher-photo-uploader-modal-title' | translate"
    [componentID]="'deposit-voucher-photo-uploader-modal'"
    [bgColorClass]="'danger'"
    [hasCloseIcon]="false"
>
    <ng-container>
        <div class="w-100">
            <h2 class="text-left">
                {{ 'deposit.voucher-photo-uploader-modal-description' | translate }}
            </h2>
        </div>
        <div class="photo-thumbnail-container ml-auto mr-auto">
            <img
                class="img-thumbnail d-block mr-auto ml-auto"
                [src]="
                    !voucherPhotoSrc || voucherPhotoSrc === ''
                        ? voucherPhotoPlaceholderUrl
                        : voucherPhotoSrc
                "
            />
        </div>

        <div class="w-100 mt-4 mb-4">
            <input
                type="text"
                id="orderCode"
                [(ngModel)]="orderCode"
                class="form-control"
                placeholder="{{
                    'collecting.return-deposit-order-code-placeholder' | translate
                }}"
            />
            <div *ngIf="isOrderCodeInvalid" class="text-danger text-center">
                <div>
                    {{
                        'collecting.return-deposit-order-code-required-placeholder'
                            | translate
                    }}
                </div>
            </div>
        </div>

        <div class="file-input" *ngIf="!voucherPhotoLoaded && !isOrderCodeInvalid">
            <input
                *ngIf="!isNewWrapper"
                type="file"
                accept="image/*"
                capture="environment"
                id="photo-input"
                onclick="this.value = null"
                (change)="onVoucherPhotoInputChanged($event)"
                hidden
            />
            <label
                class="photo-btn btn btn-block btn-info"
                for="photo-input"
                (click)="handleTakeAVoucherPhoto()"
            >
                <i class="fa fa-camera"></i>
                {{ 'deposit.voucher-photo-upload' | translate }}
            </label>
            <div *ngIf="isVoucherPhotoInvalid" class="text-danger text-center">
                <div>
                    {{ 'deposit.voucher-photo-upload' | translate }}
                    {{ 'common.is-required' | translate }}
                </div>
            </div>
        </div>

        <div class="text-center w-100" *ngIf="!isOrderCodeInvalid">
            <button
                [disabled]="isVoucherPhotoInvalid"
                class="btn btn-success w-75 mb-4"
                (click)="saveVoucherPhoto()"
            >
                {{ 'common.save' | translate }}
            </button>
            <button
                class="btn btn-secondary w-75"
                (click)="
                    modalService.setModalVisibility(
                        false,
                        'deposit-voucher-photo-uploader-modal'
                    );
                    this.resetAll()
                "
            >
                {{ 'common.cancel' | translate }}
            </button>
        </div>
    </ng-container>
</lib-modal>
