<lib-modal
    [title]="'deposit.voucher-barcode-checking-modal-title' | translate"
    [componentID]="'deposit-voucher-barcode-checking-modal'"
    [bgColorClass]="'danger'"
    [hasCloseIcon]="false"
>
    <ng-container>
        <div class="w-100">
            <h2 class="text-left">
                {{ 'deposit.voucher-barcode-checking-modal-description' | translate }}
            </h2>
        </div>
        <div class="ml-auto mr-auto mb-3 text-center">
            <strong>{{ voucherBarcode }}</strong>
        </div>
        <div class="text-center w-100">
            <button
                [disabled]="inProgress"
                class="btn btn-success w-75 mb-4"
                (click)="saveVoucher()"
            >
                {{ 'common.save' | translate }}
            </button>
            <button
                [disabled]="inProgress"
                class="btn btn-secondary w-75"
                (click)="
                    modalService.setModalVisibility(
                        false,
                        'deposit-voucher-barcode-checking-modal'
                    );
                    resetAll()
                "
            >
                {{ 'common.cancel' | translate }}
            </button>
        </div>
    </ng-container>
</lib-modal>
