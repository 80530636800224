import { Component, OnInit } from '@angular/core';
import { DepositService } from '../../deposit.service';
import { ModalService } from 'shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-deposit-voucher-barcode-checking-modal',
    templateUrl: './deposit-voucher-barcode-checking-modal.component.html',
    styleUrls: ['./deposit-voucher-barcode-checking-modal.component.scss'],
})
export class DepositVoucherBarcodeCheckingModalComponent implements OnInit {
    public inProgress: boolean = false;

    constructor(
        public modalService: ModalService,
        private _depositService: DepositService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService
    ) {}

    ngOnInit() {}

    get voucherBarcode() {
        return this._depositService.currentVoucherBarcode;
    }

    public hideModal() {
        this.modalService.setModalVisibility(
            false,
            'deposit-voucher-barcode-checking-modal'
        );
    }

    public saveVoucher() {
        this.inProgress = true;
        this._depositService.saveVoucher().subscribe(
            (response) => {
                this.hideModal();
                this.resetAll();
                const message = response.body as any;
                this._snackBar.open(
                    this._translateService.instant(message.message),
                    null,
                    {
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 5000,
                    }
                );
                this.inProgress = false;
            },
            (error) => {
                this.hideModal();
                this.resetAll();
                this._snackBar.open(
                    this._translateService.instant(error.error.message),
                    null,
                    {
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 5000,
                        panelClass: ['error-snackbar'],
                    }
                );
                this.inProgress = false;
            }
        );
    }

    public resetAll() {
        this._depositService.resetAll();
    }
}
