<div class="card text-white bg-purple">
    <div class="card-header" (click)="handleCollapse()">
        <span
            [innerHtml]="
                'my-tasks.delivering-products'
                    | translate : { orderItemNumber: task.orderItemNumber }
            "
        >
        </span>
        <span>- {{ task.orderCode }}</span
        >,
        <span
            ><strong> {{ task.providerName }}</strong></span
        >
    </div>
    <div class="card-body text-center" [collapse]="isCollapsed" [isAnimated]="true">
        <table class="table table-striped table-responsive table-borderless text-white">
            <tbody>
                <ng-container *ngIf="!isFinishedScannning; else finishedScan">
                    <tr>
                        <th scope="row">{{ 'common.provider-shop' | translate }}:</th>
                        <td>
                            {{ task.providerShop }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            {{ 'my-tasks.customer-zip-code' | translate }}:
                        </th>
                        <td>
                            {{ task.customerZipCode }}
                        </td>
                    </tr>
                </ng-container>

                <!-- TODO: Estimated Delivery Time (https://weshop.atlassian.net/browse/SP-3994) -->

                <tr>
                    <th scope="row">{{ 'my-tasks.delivery-deadline' | translate }}:</th>
                    <td>
                        <span>{{
                            task.deliveryDeadline
                                | amAdd : task.timeSlotVisualShiftMinutes : 'minutes'
                                | amDateFormat : 'HH:mm'
                        }}</span
                        ><span *ngIf="task.deliveryTimeSlotEnd != null">
                            -
                            {{
                                task.deliveryDeadline
                                    | amAdd
                                        : task.deliveryTimeSlotEnd +
                                              task.timeSlotVisualShiftMinutes
                                        : 'minutes'
                                    | amDateFormat : 'HH:mm'
                            }}
                        </span>
                    </td>
                </tr>

                <tr>
                    <th scope="row">{{ 'common.payment-type' | translate }}:</th>
                    <td>
                        {{
                            'payment-type.' + PaymentType[task.paymentType].toLowerCase()
                                | translate
                        }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ 'my-tasks.total-price' | translate }}:</th>
                    <td>
                        {{ task.deliveryFee + task.totalPrice }}
                        {{ 'common.currency' | translate }}
                    </td>
                </tr>
                <tr *ngIf="hasTotalPriceDiscount">
                    <th scope="row">
                        {{ 'my-tasks.total-price-discount' | translate }}:
                    </th>
                    <td>
                        {{ task.totalPriceDiscount }}
                        {{ 'common.currency' | translate }}
                    </td>
                </tr>
                <tr *ngIf="hasTotalPriceDiscount">
                    <th scope="row">
                        {{ 'my-tasks.customer-paid-total-price' | translate }}:
                    </th>
                    <td>
                        {{ task.deliveryFee + task.customerPaidTotalPrice }}
                        {{ 'common.currency' | translate }}
                    </td>
                </tr>

                <!-- TODO END -->

                <ng-template #finishedScan>
                    <tr>
                        <th scope="row">
                            {{ 'my-tasks.delivery-address' | translate }}:
                        </th>
                        <td>
                            <a
                                href="http://maps.google.com/maps?q={{
                                    task.deliveryAddress
                                }}"
                                class="text-white"
                                target="_blank"
                            >
                                <u>{{ task.deliveryAddress }}</u>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ 'common.phone' | translate }}:</th>
                        <td>
                            <a href="tel:{{ task.customerPhone }}" class="text-white">
                                <u>
                                    {{ task.customerPhone }}
                                </u>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ 'common.customer-name' | translate }}:</th>
                        <td>
                            {{ task.customerName }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ 'common.notes' | translate }}:</th>
                        <td>
                            {{ task.notes }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ 'common.payment-type' | translate }}:</th>
                        <td>
                            {{
                                'payment-type.' +
                                    PaymentType[task.paymentType].toLowerCase()
                                    | translate
                            }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ 'my-tasks.total-price' | translate }}:</th>
                        <td>
                            {{ task.deliveryFee + task.totalPrice }}
                            {{ 'common.currency' | translate }}
                        </td>
                    </tr>
                    <tr *ngIf="hasTotalPriceDiscount">
                        <th scope="row">
                            {{ 'my-tasks.total-price-discount' | translate }}:
                        </th>
                        <td>
                            {{ task.totalPriceDiscount }}
                            {{ 'common.currency' | translate }}
                        </td>
                    </tr>
                    <tr *ngIf="hasRokshDiscount">
                        <th scope="row">
                            {{ 'my-tasks.roksh-discount-total-price' | translate }}:
                        </th>
                        <td>
                            {{ task.rokshDiscountTotalPrice }}
                            {{ 'common.currency' | translate }}
                        </td>
                    </tr>
                    <tr *ngIf="hasTotalPriceOrRokshDiscount">
                        <th scope="row">
                            {{ 'my-tasks.customer-paid-total-price' | translate }}:
                        </th>
                        <td>
                            {{ task.deliveryFee + task.customerPaidTotalPrice }}
                            {{ 'common.currency' | translate }}
                        </td>
                    </tr>
                </ng-template>
                <tr>
                    <th colspan="2">
                        <span>{{ 'order-list.boxes-for-order' | translate }}</span>
                    </th>
                </tr>
                <tr *ngFor="let providerShopBoxList of task.providerShopBoxList">
                    <th>{{ providerShopBoxList.boxText }}</th>
                    <td>
                        <span
                            ><i
                                *ngIf="providerShopBoxList.isScanned; else scannedBox"
                                class="fa fa-check"
                            ></i>
                            <ng-template #scannedBox>
                                <i class="fa fa-exclamation-triangle"></i>
                            </ng-template>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <button
                            *ngIf="!isScannerVisible"
                            class="btn btn-info w-100"
                            expand="block"
                            (click)="openQRModal()"
                        >
                            {{ 'order-list.box-scanning' | translate }}
                        </button>
                    </td>
                </tr>

                <ng-container class="d-flex" *ngIf="isScannerVisible">
                    <lib-qr-reader (onQRScanned)="onQRCodeFound($event)"></lib-qr-reader>
                </ng-container>
                <tr>
                    <th colspan="2">
                        <span>{{ 'order-list.slot-for-order' | translate }}</span>
                    </th>
                </tr>
                <tr *ngFor="let slots of task.providerShopSlotList">
                    <th colspan="2">{{ slots.providerShopSlotText }}</th>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" [collapse]="isCollapsed" [isAnimated]="true">
        <div *ngIf="isFinishedScannning; else finishedScanning">
            <app-delivering-task-button [task]="task"></app-delivering-task-button>
        </div>
        <ng-template #finishedScanning>
            <div class="btn-group btn-block">
                <button class="btn btn-info" (click)="taskStart()">
                    {{ 'common.start-task' | translate }} ?
                </button>
                <button class="btn btn-sm btn-danger" (click)="rejectAssignment()">
                    {{ 'my-tasks.reject-delivering-task' | translate }}
                </button>
            </div>
        </ng-template>
    </div>
</div>
