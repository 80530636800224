import { ProviderShopDeliveryType } from '../types/provider-shop-delivery-type';
import { TaskListItem } from './task-list-item';
import { ProviderShopSlot } from './provider-shop-slot';
import { ProviderShopBox } from './provider-shop-box';
import { WishListOrder } from './wish-list-order';
import { SendBirdChatChannel } from './sendbird-chat-channel';

export class DeliveringTaskListItem extends TaskListItem {
    public customerPhone: string;
    public customerEmail: string;
    public delivererEmail: string;
    public totalPrice: number;
    public paidTotalPrice: number;
    public customerPaidTotalPrice: number;
    public totalPriceDiscount: number;
    public customerTotalPrice: number;
    public hasTotalPriceDiscount: boolean;
    public rokshDiscountTotalPrice: number;
    public hasRokshDiscount: boolean;
    public deliveryFee: number;
    public isExtraDelivery: boolean;
    public deliveryType: ProviderShopDeliveryType;
    public providerShopBoxList: ProviderShopBox[];
    public providerShopSlotList: ProviderShopSlot[];
    public wishListOrders: WishListOrder[];
    public sendBirdChatChannel?: SendBirdChatChannel;
}
