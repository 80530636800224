import { ModalService } from 'shared';
import { Component } from '@angular/core';
import { DepositService } from './deposit.service';
import { CollectingStatesService } from '../collecting/collecting-states.service';

@Component({
    selector: 'app-deposit',
    templateUrl: './deposit.component.html',
    styleUrls: ['./deposit.component.scss'],
})
export class DepositComponent {
    constructor(
        private _modalService: ModalService,
        private _depositService: DepositService,
        private _states: CollectingStatesService
    ){}

    public setBarcode(gtin: string): void
    {
        this._depositService.setVoucherBarcode(gtin);
        this._states.setBarcodeReaderModalVisible(false);
        this._modalService.setModalVisibility(
            true,
            'deposit-voucher-barcode-checking-modal'
        );
    }

    public showDepositVoucherPhotoUploaderModal() {
        this._depositService.resetAll();
        this._modalService.setModalVisibility(
            true,
            'deposit-voucher-photo-uploader-modal'
        );
    }
}
