import { Component, Inject, OnInit } from '@angular/core';
import { AUTH_SERVICE_IMPL, IAuthenticationService, SendBirdChatChannel } from 'shared';
import { Router } from '@angular/router';
import { ColorClass } from 'shared';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    public ColorClass = ColorClass;
    customerChatInfo = {} as SendBirdChatChannel;

    constructor(
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _router: Router,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {}

    ngOnInit(): void {
        if (!this._authService.isAuthenticated()) {
            this._router.navigate(['/login']);
        }
        const currentUser = this._authService.getUserFromStorage();
        console.log('currentUser_ ', currentUser);
        if (currentUser) {
            console.log(
                'currentUser?.supportChatUserID ',
                currentUser?.supportChatUserID
            );
            this.customerChatInfo.userId = currentUser?.supportChatUserID;
            this.customerChatInfo.nickName = currentUser?.supportChatUserNickName;
            this.customerChatInfo.channelUrl = currentUser?.supportChatChannelID;
        }
    }

    handleFrontendVersion() {
        if (window.ReactNativeWebView) {
            this._iosAndAndroidNatvieFunctionsService.newFrontendUrlChoose();
        } else {
            this._iosAndAndroidNatvieFunctionsService.selectFrontendVersion(
                'Válasszon verziót.'
            );
        }
    }

    goToSupportChat() {
        console.log('customerChatInfo', this.customerChatInfo);
        this._iosAndAndroidNatvieFunctionsService.openSendbird(this.customerChatInfo);
    }
}
