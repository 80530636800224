<ng-template #replacementEditorModal let-modal>
    <div class="modal-header modal-fullscreen">
        <h4 class="modal-title" id="modal-basic-title">
            {{ 'collecting.replacement-product' | translate }}
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p
            [innerHtml]="
                'collecting.replacement-editor-modal-text'
                    | translate : { name: orderItem.orderItemName }
            "
        ></p>
        <form [formGroup]="replacementItemEditorForm">
            <div class="form-group">
                <label class="cols-sm-2 control-label" for="itemName">{{
                    'collecting.item-name' | translate
                }}</label>
                <div class="cols-sm-10">
                    <div class="input-group is-invalid">
                        <input
                            type="text"
                            id="name"
                            formControlName="name"
                            class="form-control"
                            placeholder="Product Name"
                            required
                            autofocus
                            [readOnly]="states.hasMarkupPayment"
                            [ngClass]="{
                                'is-invalid': isSubmitted && fields.name.errors
                            }"
                        />
                    </div>
                    <div
                        *ngIf="isSubmitted && fields.name.errors"
                        class="invalid-feedback"
                    >
                        <div *ngIf="fields.name.errors.required">
                            {{ 'collecting.product-name' | translate }}
                            {{ 'collecting.is-required' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="unit-price" class="cols-sm-2 control-label">{{
                    'collecting.unit-price' | translate
                }}</label>
                <div class="cols-sm-10">
                    <div class="input-group is-invalid">
                        <input
                            type="number"
                            id="unit-price"
                            formControlName="unitPrice"
                            class="form-control"
                            placeholder="Unit Price"
                            required
                            autofocus
                            [readOnly]="
                                states.hasMarkupPayment || states.isMetroRetailerModel
                            "
                            [ngClass]="{
                                'is-invalid': isSubmitted && fields.unitPrice.errors
                            }"
                        />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                {{ 'common.currency' | translate }}
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="isSubmitted && fields.unitPrice.errors"
                        class="invalid-feedback"
                    >
                        <div *ngIf="fields.unitPrice.errors.required">
                            {{ 'collecting.unit-price' | translate }}
                            {{ 'common.is-required' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="cols-sm-2 control-label" for="quantity">{{
                    'collecting.quantity' | translate
                }}</label>
                <div class="cols-sm-10">
                    <div class="input-group is-invalid">
                        <input
                            id="orderedQuantity"
                            formControlName="orderedQuantity"
                            class="form-control"
                            placeholder="Quantity"
                            [readonly]="true"
                        />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                {{ 'collecting.' + orderItem.unit | translate }}
                            </div>
                        </div>
                    </div>
                    <hr class="no-margin" />

                    <quantity
                        class="my-2"
                        id="quantity"
                        formControlName="quantity"
                        [orderItem]="orderItem"
                    ></quantity>

                    <div
                        *ngIf="isSubmitted && fields.quantity.errors"
                        class="invalid-feedback"
                    >
                        <div
                            class="error-text text-center"
                            *ngIf="fields.quantity.errors.required"
                        >
                            {{ 'collecting.quantity' | translate }}
                            {{ 'collecting.is-required' | translate }}
                        </div>
                        <div
                            class="error-text text-center"
                            *ngIf="fields.quantity.errors.pattern"
                        >
                            {{ 'collecting.quantity-format-error' | translate }}
                        </div>
                    </div>

                    <div class="row">
                        <div
                            class="input-group is-invalid input-group is-invalid col-12 w-100 d-block"
                            *ngFor="
                                let orderItemDepositProduct of orderItem.orderItemDepositProductList
                            "
                        >
                            <strong
                                class="float-right"
                                *ngIf="!orderItemDepositProduct.depositUnitTypeIsOptional"
                            >
                                + {{ orderItemDepositProduct.price }}
                                {{ 'common.currency' | translate }}
                                {{ orderItemDepositProduct.unitName }}
                            </strong>
                            <ng-container
                                *ngIf="orderItemDepositProduct.depositUnitTypeIsOptional"
                            >
                                <hr />

                                <label
                                    class="control-label float-left"
                                    style="width: 40%"
                                    for="optionalDepositProductQuantity"
                                >
                                    {{ orderItemDepositProduct.depositUnitTypeName }}
                                </label>
                                <div class="input-group float-right" style="width: 60%">
                                    <input
                                        type="number"
                                        id="optionalDepositProductQuantity"
                                        [value]="optionalDepositProductQuantity"
                                        (input)="changeDepositValue($event.target.value)"
                                        class="form-control"
                                        placeholder="Optional Deposit Product Quantity"
                                        [readonly]="false"
                                    />
                                    <div class="input-group-append">
                                        <div class="input-group-text">
                                            {{
                                                orderItemDepositProduct.depositUnitTypeName
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="px-3 mb-2">
        <button
            type="button"
            (click)="onSubmit()"
            class="btn btn-success btn-lg btn-block mt-2"
        >
            <span class="font-weight-bold text-smaller">{{
                'collecting.add-cart' | translate
            }}</span>
        </button>
        <lib-btn-close></lib-btn-close>
    </div>
</ng-template>
