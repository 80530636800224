import { UserRole } from '../types/user-role';
import { Policy } from '../types/policy';
import { IPolicyRoles } from '../types/i-policy-roles';
import { UserSetting } from './user-setting';
import { ActorType } from '../types/actor-type';

export class User {
    public userID: string;
    public username: string;
    public email: string;
    public roles: string[];
    public efficiency: number;
    public providerID: number;
    public authToken?: string;
    public firstName: string;
    public lastName: string;
    public actorType: ActorType;
    public actorIp: string;
    public actorUserAgent: string;
    public actorUserName: string;
    public supportChatChannelID: string;
    public supportChatUserID: string;
    public supportChatUserNickName: string;
    public userSettings: UserSetting[];

    constructor(private _policyRoles: IPolicyRoles[] = []) {}

    public hasRoles(searchedRoles: UserRole[]): boolean {
        return (
            this.roles !== undefined &&
            this.roles.length > 0 &&
            searchedRoles.some((x) => this.roles.includes(UserRole[x].toLowerCase()))
        );
    }
    public can(policy: Policy): boolean {
        const policyRole = this._policyRoles.find((x) => x.policy === policy);

        return policyRole !== undefined && this.hasRoles(policyRole.roles);
    }

    public getUserSettingValue(section: string, key: string): string {
        return this.getUserSetting(section, key)?.value;
    }

    public setUserSetting(userSetting: UserSetting): void {
        const userSettingIndex = this.getUserSettingIndex(
            userSetting.section,
            userSetting.key
        );
        if (userSettingIndex > -1) {
            this.userSettings[userSettingIndex].value = userSetting.value;
        } else {
            this.userSettings.push(userSetting);
        }
    }

    private getUserSettingIndex(section: string, key: string): number {
        return this.userSettings.findIndex((x) => x.section === section && x.key === key);
    }

    private getUserSetting(section: string, key: string): UserSetting {
        return this.userSettings.find((x) => x.section === section && x.key === key);
    }
}
