import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepositComponent } from './deposit.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSelectModule } from 'ngx-select-ex';
import { DepositVoucherBarcodeReaderModalComponent } from './deposit-modals/deposit-voucher-barcode-reader-modal/deposit-voucher-barcode-reader-modal.component';
import { DepositVoucherPhotoUploaderModalComponent } from './deposit-modals/deposit-voucher-photo-uploader-modal/deposit-voucher-photo-uploader-modal.component';
import { DepositVoucherBarcodeCheckingModalComponent } from './deposit-modals/deposit-voucher-barcode-checking-modal/deposit-voucher-barcode-checking-modal.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';
import { SharedModule } from 'shared';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        DepositComponent,
        DepositVoucherBarcodeReaderModalComponent,
        DepositVoucherPhotoUploaderModalComponent,
        DepositVoucherBarcodeCheckingModalComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        NgxSelectModule,
        NgbNavModule,
        FormsModule,
        AppSharedModule,
        SharedModule,
    ],
})
export class DepositModule {}
