import { Component, Input } from '@angular/core';
import { CollectingTaskListItem, SendBirdChatChannel } from 'shared';
import { environment } from 'dm-src/environments/environment';
import { PaymentType } from 'shared';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';

@Component({
    selector: '[collecting-task-item]',
    templateUrl: './collecting-task-item.component.html',
    styleUrls: ['./collecting-task-item.component.scss'],
})
export class CollectingTaskItemComponent {
    @Input() task: CollectingTaskListItem;
    public isCollapsed = true;
    public dateFormat: string;
    customerChat = false;
    customerChatInfo?: SendBirdChatChannel;

    public PaymentType = PaymentType;

    constructor(
        private _IosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {
        this.dateFormat = environment.deliveryDateFormat;
    }

    ngOnInit(): void {
        console.log('this.task', this.task);
        if (this.task?.sendBirdChatChannel?.channelUrl) {
            this.customerChat = true;
            this.customerChatInfo = this.task.sendBirdChatChannel;
        }
    }

    openChat(): void {
        this._IosAndAndroidNatvieFunctionsService.openSendbird(this.customerChatInfo);
    }
}
