import { SendBirdChatChannel } from './sendbird-chat-channel';
import { TaskListItem } from './task-list-item';
import { WishListOrder } from './wish-list-order';

export class CollectingTaskListItem extends TaskListItem {
    public customerPhone: string;
    public usePaperBag: boolean;
    public wishListOrders: WishListOrder[];
    public sendBirdChatChannel?: SendBirdChatChannel;
}
