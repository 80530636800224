import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { OrderRemovedItemReasons } from 'dist/shared/lib/types/order-removed-item-reasons';
import { Observable } from 'rxjs';
import { OrderItem } from 'shared';
import { NativeTakePhotoService } from 'dm-src/services/native-take-photo/native-take-photo.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';

@Component({
    selector: 'app-order-items-container',
    templateUrl: './order-items-container.component.html',
    styleUrls: ['./order-items-container.component.scss'],
})
export class OrderItemsContainerComponent implements OnInit, AfterViewInit {
    @Input() orderItems: Observable<OrderItem[]>;
    @Input() orderRemovedItemReasons: Observable<OrderRemovedItemReasons[]>;
    @Input() currentTotal: Observable<number>;
    @Input() sumDeposit: Observable<number>;
    @Input() parent: string;
    @Input() name: string;
    @Input() title: string;
    @Input() classes: string;
    @Input() icon: string;

    public isCollapsed = true;
    public isWrapping = false;

    constructor(
        private _nativeTakePhotoService: NativeTakePhotoService,
        private _isWrappingService: IsWrappingService,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {}

    ngOnInit() {
        this.isWrapping = this._isWrappingService.isWrapping();
    }

    ngAfterViewInit() {
        if (this.isWrapping) {
            setTimeout(() => {
                if (this._isWrappingService.isWrapping()) {
                    this._nativeTakePhotoService.init();
                    this.isWrapping = true;
                }
            }, 0);
        }
    }
}
